import { connect } from "react-redux";
import React from 'react';
import { Select, Spin } from 'antd';
import { LoadingOutlined } from "@ant-design/icons"
import { selectedCustomerUpdate } from '../redux/actions';
import Axios from 'axios';
import error from '../assets/error.png';
import 'antd/dist/antd.css';
import styles from './customerSelector.module.css';
import { customerStateFactory } from '../redux/reducer'
const classNames = require('classnames');
/**
 * 
 * @param {import('../redux/store').appMasterStare} state 
 * @returns {componentState}
 */
const mapStateToProps = state => {
    return {
        theme: state.appState.theme,
        locale: state.appState.locale,
        customerState: state.masterState.customer,
        selectedCompany: state.masterState.cinfo.selectedCompany,
        apiHost: state.appState.apiHost,
        token: state.appState.userState.jwt
    };
}
const mapDispatchToProps = dispatch => {
    return {
        updateCustomerState: customer => dispatch(selectedCustomerUpdate(customer))
    };
}

/**
 * @typedef componentState
 * @prop {import('../types/theme').darkTheme} theme
 * @prop {string}  locale
 * @prop {React.ClassAttributes<any>} [innerRef]
 * @prop {string}  apiHost
 * @prop {import('../redux/reducer').customerState} customerState
 * @prop {import('../redux/reducer').EAMCompany} selectedCompany
 * @prop {function(import('../redux/reducer').customerState):void} updateCustomerState
 * @prop {function():void} onSelect
 * @extends {React.Component<componentState>}
 */
class CustomerSelector extends React.Component {
    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
        this.selectChange = this.selectChange.bind(this);
        this.state = {
            value: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.keyDown = this.keyDown.bind(this);
        this.state = { isOpen: false };
    }
    async componentDidMount() {
        if (!this.props.customerState.dataFetched && !this.props.customerState.list) {
            await this.getData();
        }
    }
    async getData(customerFilter) {
        if (!this.props.selectedCompany)
            return;
        let customers = undefined;
        try {
            this.props.updateCustomerState(customerStateFactory(undefined, true));
            let resp = await Axios.get(`${this.props.apiHost}/collections/customers?cinfo=${this.props.selectedCompany.key}&filter=${customerFilter}`, {
                withCredentials: true,
                responseType: 'json',
                headers: {
                    Authorization: `Bearer ${this.props.token}`
                }
            });
            customers = resp.data.responseData.customers;
        } catch (err) {
            this.props.updateCustomerState(customerStateFactory(undefined, true, true, err.response.status + ': ' + err.response.statusText));
            return;
        }
        this.props.updateCustomerState(customerStateFactory(customers, true));
    }
    getChildren() {
        const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
        if (this.props.customerState.list) {
            return this.props.customerState.list.map(cinfo => (
                <Select.Option key={cinfo.cuscode} label={cinfo.cusname + ' ' + cinfo.place}>
                    <div>
                        <span style={{
                            display: 'inline-flex', margin: 0
                        }}>{cinfo.cusname}</span>
                        <span style={{
                            float: 'right',
                            margin: 0
                        }}>{cinfo.place}</span>
                    </div>
                </Select.Option>));
        } else if (this.props.customerState.fetchError) {
            return (
                <Select.Option disabled key='error'>
                    <div style={
                        {
                            height: 'auto',
                            display: 'block',
                            textAlign: 'center'
                        }
                    } className={classNames(styles.eamCustomerSpin, styles.ecsiFetching, styles[this.props.theme.className])}>
                        <img src={error} style={
                            {
                                display: 'block',
                                height: '100px',
                                width: '100px',
                                textAlign: 'center',
                                margin: 'auto'
                            }
                        } />
                        {this.props.customerState.fetchErrorMsg}
                    </div>
                </Select.Option>
            );
        } else if (this.props.customerState.dataFetching) {
            return <Select.Option value='loading' disabled={true}>
                <div className={classNames(styles.eamCustomerSpin, styles.ecsiFetching, styles[this.props.theme.className])}>
                    <Spin indicator={antIcon} />
                    <p>Fetching Customer List</p>
                </div>
            </Select.Option>
        } else if (!this.props.selectedCompany) {
            return (
                <Select.Option disabled key='error'>
                    <div style={
                        {
                            height: 'auto',
                            display: 'block',
                            textAlign: 'center'
                        }
                    } className={classNames(styles.eamCustomerSpin, styles.ecsiFetching, styles[this.props.theme.className])}>
                        <img src={error} style={
                            {
                                display: 'block',
                                height: '100px',
                                width: '100px',
                                textAlign: 'center',
                                margin: 'auto'
                            }
                        } />
                        Select a Company
                    </div>
                </Select.Option>);

        } else {
            return null;
        }
    }
    DropDownOpen(isOpen) {
        this.setState({ isOpen });
    }
    keyDown(e) {
        if (e.key == 'Escape' || e.key == 'Tab')
            this.DropDownOpen(false);
        if (e.key == 'Enter') {
            this.DropDownOpen(!this.state.isOpen);
        }
    }
    selectChange(selected, option) {
        if (this.props.onSelect) {
            this.props.onSelect({
                label: option.props.label,
                key: selected
            });
        }
    }
    getdefault(sc) {
        if (sc)
            return sc;
        else
            return undefined;
    }
    handleChange(v) {
        this.setState({
            value: v
        });
    }
    render() {
        return (
            <div onBlur={() => this.DropDownOpen(false)}>
                <h3>Customer</h3>
                <Select open={this.state.isOpen} onInputKeyDown={this.props.onKeyPress} onKeyDown={this.keyDown} onFocus={() => this.DropDownOpen(true)} ref={this.props.innerRef} showSearch disabled={this.props.disabled} value={this.props.disabled ? this.props.defaultDispalyValue : this.state.value} showArrow={false} filterOption={false} optionLabelProp='label' onSearch={this.getData} onChange={this.selectChange} onSelect={this.selectChange} dropdownClassName={classNames(styles.eamCustomerSelectItem, styles[this.props.theme.className])} className={classNames(styles.eamCustomerSelect, styles[this.props.theme.className])} onChange={this.handleChange} placeholder='Select a Customer'>
                    {
                        this.getChildren()
                    }
                </Select >
            </div>);
    }
}
const EAMCustomerSelector = connect(mapStateToProps, mapDispatchToProps)(CustomerSelector);
export default EAMCustomerSelector;

