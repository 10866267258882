import 'antd/dist/antd.less';
import { useHistory } from 'react-router-dom'
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { apiServerStatusUpdate, userStateUpdate } from '../redux/actions';
import React from 'react';
import Axios, { } from 'axios';
import { Modal } from 'antd';
import * as Icons from '@ant-design/icons'
import warning from '../assets/warning.png';
import { serverStateFactory, message, userStateStateFactory } from '../redux/reducer'
import styles from './EAMAuthentication.module.css';
import 'antd/dist/antd.css';
import { imageEncode, parseCharset, parseContentType } from '../types/helper';
const classNames = require('classnames');
const mapStateToProps = state => {
    return {
        userState: state.appState.userState,
        serverState: state.appState.serverState,
        theme: state.appState.theme,
        apiHost: state.appState.apiHost,
    };
}

const mapDispatchToProps = ((dispatch) => {
    return {
        serverStateUpdate: (status) => dispatch(apiServerStatusUpdate(status)),
        userStateUpdate: (userState) => dispatch(userStateUpdate(userState))
    };
});
class Authenticater extends React.Component {
    constructor(props) {
        super(props);
        this.onclick = this.onclick.bind(this);
    }
    async authenticate() {
        await this.fecthData();
    }
    componentDidMount() {
        this.authenticate();
    }
    componentDidUpdate(prevProps, prevState) {
        let button = document.querySelector("ant-modal-fooetr > ant-btn-primary");
        if (button) {
            button.focus();
        }
        if (this.props.userState !== prevProps.userState) {
            this.checkAuth();
        }
    }
    async fecthData() {
        let user = this.fetchFromCache();
        if (!user) {
            this.props.serverStateUpdate(serverStateFactory(true, message.loginMessage, false));
            return;
        }
        this.props.userStateUpdate(user);
    }
    fetchFromCache() {
        try {
            let user = JSON.parse(localStorage.getItem('user'));
            return user;
        }
        catch { }
        return null;
    }
    async fetchUserPhoto() {
        let photo = null;
        try {
            let photoObj = await Axios.get(this.props.userState.userImageUrl, {
                responseType: 'arraybuffer',
                headers: {
                    Authorization: `Bearer ${this.props.userState.jwt}`
                },
                params: {
                    O365Token: this.props.userState.O365Token
                }
            });
            let charset = parseCharset(photoObj.headers['content-type']);
            let ctype = parseContentType(photoObj.headers['content-type']);
            photo = imageEncode(photoObj.data, ctype);
        } catch { } finally {
            return photo;
        }
    }
    async checkAuth() {
        if (this.props.userState?.isAuthenticated) {
            if (!this.props.userState?.userPhotoData)
                await this.fetchUserPhoto();
            return;
        }

        if (!this.props.userState || !this.props.userState.jwt) {
            localStorage.clear();
            this.props.serverStateUpdate(serverStateFactory(true, message.loginMessage, false));
        }
        let xhr = new XMLHttpRequest();
        xhr.addEventListener('load', (() => {
            if (xhr.status === 401) {
                localStorage.clear();
                this.props.userStateUpdate(userStateStateFactory());
                this.props.serverStateUpdate(serverStateFactory(true, message.loginMessage, false));
            } else if (xhr.status === 200) {
                let userinfo = xhr.response.responseData;
                if (this.props.userState.userEmail !== userinfo.email || this.props.userState.userAdminLevel !== userinfo.isAdmin) {
                    this.props.userState.userEmail = userinfo.email;
                    this.props.userState.userAdminLevel = userinfo.isAdmin;
                    this.props.userState.userDisplayName = userinfo.name
                    localStorage.setItem('user', JSON.stringify(this.props.userState));
                    this.props.userState.isAuthenticated = true;
                    this.props.userStateUpdate(Object.assign({}, this.props.userState));
                } else if (this.props.userState && !this.props.userState.userPhotoData) {
                    (async () => {
                        let photo = await this.fetchUserPhoto();
                        this.props.userState.userPhotoData = photo;
                        localStorage.setItem('user', JSON.stringify(this.props.userState));
                        this.props.userState.isAuthenticated = true;
                        this.props.userStateUpdate(Object.assign({}, this.props.userState));
                    })();
                }
                this.onAuthSuccess();
            } else {
                this.props.serverStateUpdate(serverStateFactory(true, message.errorMessage + ', Code: ' + xhr.status, true));
            }
        }));
        xhr.onerror = (e) => {
            this.props.serverStateUpdate(serverStateFactory(true, message.APIoffline, true));
        }
        xhr.withCredentials = true;
        xhr.open('GET', `${this.props.apiHost}/isLogged`);
        xhr.setRequestHeader('Authorization', `Bearer ${this.props.userState.jwt}`);
        xhr.responseType = 'json';
        xhr.send();
    };
    onAuthSuccess() {
        this.props.serverStateUpdate(serverStateFactory(false, message.serverOnline, false));

    };
    redirecttoLogin(xhr) {
        xhr.open('GET', `${this.props.apiHost}/collections/login`);
        xhr.responseType = 'json';
        xhr.send();
    };
    completeLogin(token, O365Token) {
        let xhr = new XMLHttpRequest();
        xhr.addEventListener('load', (() => {
            if (xhr.status === 401) {
                localStorage.clear();
                this.props.serverStateUpdate(serverStateFactory(true, message.loginMessage, false));
            } else if (xhr.status === 200) {
                let userinfo = xhr.response.responseData;
                let userstateObj = userStateStateFactory(token, O365Token, userinfo.name, userinfo.email, null, userinfo.isAdmin);
                localStorage.setItem('user', JSON.stringify(userstateObj));
                userstateObj.isAuthenticated = true;
                this.props.userStateUpdate(userstateObj);
                this.onAuthSuccess();
            } else {
                this.props.serverStateUpdate(serverStateFactory(true, message.errorMessage + ', Code: ' + xhr.status, true));
            }
        }));
        xhr.withCredentials = true;
        xhr.open('GET', `${this.props.apiHost}/isLogged`);
        xhr.setRequestHeader('Authorization', `Bearer ${token}`);
        xhr.responseType = 'json';
        xhr.send();
        //this.onAuthSuccess();
    }
    onclick() {
        let h = 450;
        let w = 600;
        let dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
        let dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;
        let width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        let height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;
        let left = ((width / 2) - (w / 2)) + dualScreenLeft;
        let top = ((height / 2) - (h / 2)) + dualScreenTop;
        let wndhndl = {
            hndl: window.open('', 'popup', 'width=' + w + ',height=' + h + ',location=0,toolbar=0,resizable=0,scrollbars=0,menubar=0,titlebar=0,left=' + left + ',top=' + top)
        }
        try {
            wndhndl.hndl.focus();
        } catch (e) {
            alert("Pop-up Blocker is enabled! Please add this site to your exception list.");
            return;
        }
        const messageListener = (event) => {
            if (event.data) {
                if (event.origin !== this.props.apiHost)
                    return;
                if (event.data.authTransferToken) {
                    this.completeLogin(event.data.authTransferToken, event.data.O365);
                }
            }
            window.removeEventListener('message', messageListener);
        };
        window.addEventListener('message', messageListener);
        let xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.addEventListener('load', (() => {
            if (xhr.status === 200) {
                wndhndl.hndl = window.open(xhr.response.responseData.loginUrl, 'popup');
            }
        }));
        this.redirecttoLogin(xhr);
    };
    render() {
        if (this.props.serverState.authRequired) {
            return (
                <Modal className={classNames(styles.authModal, this.props.serverState.reloadCompulsion ? styles.noBorder : null, styles[this.props.theme.className])} visible={true} onOk={this.onclick} maskClosable={false} width={500} bodyStyle={
                    {
                        textAlign: "center",
                        display: "inline",
                    }
                } closable={false} cancelButtonProps={{
                    style: {
                        display: 'none'
                    }
                }}
                    okButtonProps={this.props.serverState.reloadCompulsion ? { style: { display: 'none' } } : {
                        style: { display: 'inline' }
                    }}>
                    <div style={{
                        textAlign: 'center',
                        marginTop: 0,
                        display: 'block'
                    }}>
                        <img src={warning} width={100} height={100} />
                        <p style={{
                            marginTop: 30
                        }}> {this.props.serverState.statusMessage}</p>
                    </div>
                </Modal >
            );
        } else {
            return null;
        }
    }
}


const EAMAuthentication = connect(mapStateToProps, mapDispatchToProps)(Authenticater);
export default EAMAuthentication;