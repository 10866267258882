import React from 'react';
import { Select } from 'antd';
import styles from './selectWrapper.module.css';
import classNames from 'classnames';
class selectWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropDownState: false,
        };
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onKeyDown = this.onKeyDown(this);
    }
    onFocus() {
        this.setState({
            dropDownState: true
        })
    }
    onBlur() {
        this.setState({
            dropDownState: false
        })
    }
    onKeyDown(e) {
        if (!this.state.dropDownState)
            return;
        e.stopPropagation();
        if (e.key = 'Enter') {
            this.onBlur();
        }
    }
    render() {
        return (
            <div className={classNames({
                [styles.selectWrapper]: true,
                [styles.selected]: this.state.dropDownState,
                [styles.darkTheme]: true
            })} >
                <Select listItemHeight={this.props.listItemHeight} listHeight={this.props.listHeight} showArrow={false} onInputKeyDown={this.onKeyDown} bordered={this.props.bordered !== false} onFocus={this.onFocus} onBlur={this.onBlur} open={this.state.dropDownState} ref={this.props.innerRef} style={{ width: this.props.width,fontSize:this.props.fontSize }} dropdownStyle={{ width: this.props.width,fontSize:this.props.fontSize }} size={this.props.size}>
                    {
                        this.props.options.map((x, i) => {
                            return (
                                <Select.Option className={classNames({
                                    [styles.default]:this.props.listSize===undefined,
                                    [styles.smaller]:this.props.listSize==='smaller',})} value={i}>
                                    {
                                        (x.customRender instanceof Function
                                            ?
                                            (x.customRender())
                                            :
                                            (x.customRender !== undefined
                                                ?
                                                x.customRender
                                                :
                                                <div>{x}</div>
                                            )
                                        )
                                    }
                                </Select.Option>
                            );
                        })
                    }
                </Select>
            </div>
        );
    }
}

export default selectWrapper;