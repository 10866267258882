import React from 'react';
import { Modal } from 'antd'
import styles from './FilterInterface.module.css';
import classNames from 'classnames';
import SelectWrapper from './selectWrapper';
import Nt from './navigatableTable';
import { connect } from 'react-redux';
import * as Icons from '@ant-design/icons';
import KeyListenPure from './KeyListener';
const mapStateToProps = state => {
    return {
        theme: state.appState.theme,
        locale: state.appState.locale,
    };
}
const ANTMODALPADDING = 24;
class FilterInterface extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visibility: false,
            data: [{}],
            height: 200,
            width: 800,
        };
        
        this.refCollection = {
            expressionRef: React.createRef()
        }
        this.numberRow = (
            <div className={classNames(styles.sortRow)}>
                <SelectWrapper bordered={false} innerRef={this.refCollection.expressionRef} width={200} options={[{ value: "Greater than >=", index: 0 }, { value: "Less than >=", index: 1 }, { value: "Not Equal >=", index: 2 }]} />
            </div>);
        this.bindFunctions();
        this.keyLisenProps = {
            down:
                { downKeys: ['f4', 'escape'], downCallBack: this.onKeyDown },
                up:{}
        }
    }
    bindFunctions() {
        this.getFilterColumns = this.getFilterColumns.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.getspin = this.getspin.bind(this);
    }
    getFilterColumns() {
        return [
            {
                title: "Columns",
                render: (text, record, extra) => {
                    return (<SelectWrapper width={175} size={'small'} listSize={'smaller'} uniqueKey={`${extra.rowIndex}-${extra.columnIndex}`} options={this.props.fields} />);
                },
                dataType: 'text',
                sorter: false,
                width: 200,
            },
            {
                title: "Condition",
                render: (text, record) => {
                    return (<span>{null}</span>);
                },
                dataType: 'text',
                sorter: false,
                width: 200,
            },
            {
                title: "Value",
                render: (text, record) => {
                    return (<span>{null}</span>);
                },
                dataType: 'text',
                sorter: false,
                width: 200,
            },
            {
                title: "And | Or",
                render: (text, record) => {
                    return (<span>{null}</span>);
                },
                dataType: 'text',
                sorter: false,
            },
        ]
    }
    getspin() {
        let antIcon = <Icons.Loading3QuartersOutlined style={{ fontSize: '5em' }} spin />
        return {
            indicator: antIcon, style: {
                color: this.props.theme.primary1
            }
        };
    }
    get getVisibility() {
        return this.state.visibility;
    }
    makeVisible(isVisible) {
        if (isVisible === undefined || isVisible)
            this.setState({ visibility: true });
        else
            this.setState({ visibility: false });
    }
    handleFocus() {
        if (this.refCollection.expressionRef.current) {
            this.refCollection.expressionRef.current.focus();
        }
    }
    componentDidMount() {
        this.handleFocus();
    }
    componentDidUpdate() {
        this.handleFocus();
    }
    onKeyDown(e) {
        console.log(e);
        e.stopPropagation();
        if (e.key === 'F4') {
            e.preventDefault();
            this.makeVisible(!this.state.visibility);
        }
        else if (e.key === 'Escape'){
            e.preventDefault();
            this.makeVisible(false);
        }
    }
    render() {
        return (
            <>
                <KeyListenPure down={this.keyLisenProps.down} />
                <Modal centered width={this.state.width} maskClosable={false} visible={this.state.visibility} footer={null} closeIcon={false} closable={false} destroyOnClose={true} keyboard={false}>
                    <Nt columnSelectable loading={this.getspin()} filterInterface={false} scroll={{ y: this.state.height - (ANTMODALPADDING * 2), x: this.state.width - (ANTMODALPADDING * 2) }} tabIndex={50} onEnter={undefined} onDelete={this.undefined} theme={this.props.theme.className} columns={this.getFilterColumns()} dataSource={this.state.data} pagination={false} rowHeight={35} columnSelectable={true} dataSource={this.state.data} />
                </Modal>
            </>
        );
    }
}

export default connect(mapStateToProps, undefined, undefined, { forwardRef: true })(FilterInterface);