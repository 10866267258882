
import React from 'react';
import { Input } from 'antd';
import { uuidv4 } from '../types/helper'
import classNames from 'classnames';
import styles from './performaceDatePicker.module.css';
import KeyListenPure from './KeyListener';
import { isAfter, isBefore, parse, isSameDay } from 'date-fns'
import format from 'date-fns/esm/fp/format/index.js';

class PerformanceModal extends React.Component {
    constructor(props) {
        super(props);
        this.displayFormat = "dd-MM-yyyy";
        this.state = this.componentStateFactory(this.props.defaultVisibility);
        this.guid = `performanceDatePicker_${uuidv4()}`;
        this.datePickerRef = React.createRef();
        this.bindFunctions();
        this.selectedDate = null;
        this.keyListenerDown = {
            down: {
                downCallBack: this.onKeydown,
                exceptions: ['escape'],
            }
        }
        console.log(styles);
    }
    get referanceDate() {
        return this.props.referanceDate || new Date()
    }
    bindFunctions() {
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onKeydown = this.onKeydown.bind(this);
    }
    componentStateFactory(state) {
        return {
            value: format(this.displayFormat, this.props.defaultValue || new Date()),
            isVisible: state,
            isFocused: false,
            isError: false
        }
    }
    validateDate(d) {
        return (
            (
                this.props.minDate === undefined
                ||
                (
                    this.props.minDate instanceof Date
                    &&
                    (
                        isAfter(d, this.props.minDate)
                        ||
                        isSameDay(this.props.minDate, d)
                    )
                )
            )
            &&
            (
                this.props.maxDate === undefined
                ||
                (
                    this.props.maxDate instanceof Date
                    &&
                    (
                        isBefore(d, this.props.maxDate)
                        ||
                        isSameDay(this.props.maxDate, d)
                    )
                )
            )
        )
    }
    setDate(date) {
        if (!isNaN(+date))
            this.setState({
                value: format(this.displayFormat, date),
                isError: false
            });
    }
    parseDate() {
        let formatArray = (this.props.format instanceof Array && this.props.format) || ["dd-MM-yyyy"];
        for (let dateFormat of formatArray) {
            let parsedDate = parse(this.state.value, dateFormat, this.referanceDate);
            if (!isNaN(+parsedDate)) {
                if (!dateFormat.includes('d')) {
                    if (this.props.type?.toLowerCase() === 'end')
                        parsedDate.setDate(new Date(parsedDate.getFullYear(), parsedDate.getMonth() + 1, 0).getDate());
                    else
                        parsedDate.setDate(1);

                }
                if (this.validateDate(parsedDate)) {
                    this.setState({
                        value: format(this.displayFormat, parsedDate),
                        isError: false
                    });
                    this.props.onChange instanceof Function && this.props.onChange(parsedDate);
                    return parsedDate;
                }
                break;
            }
        }
        this.setState({
            isError: true
        });
        this.props.onChange instanceof Function && this.props.onChange(new Date(NaN));

    }
    componentDidMount() {
        if (
            this.datePickerRef.current
            &&
            this.props.innerRef
        ) {
            this.props.innerRef.current = this.datePickerRef.current;
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.value && !isSameDay(this.props.value, prevProps.value))
            this.setDate(this.props.value)
    }
    onKeydown(e) {
        if (e.key.toLowerCase() === 'enter') {
            this.parseDate();
        }
    }
    componentWillUnmount() {
        if (this.props.innerRef) {
            this.props.innerRef.current = undefined;
        }
    }
    changeDropDownVisibility(state, cb) {
        this.setState({
            isVisible: state
        }, () => cb ? cb() : undefined);
    }
    onChange(e) {
        this.setState(v => ({ value: e?.target?.value ?? "" }))
    }
    get dropDownVisibility() {
        return this.state.isVisible;
    }
    focus(delay) {
        setTimeout(() => {
            if (this.datePickerRef.current)
                this.datePickerRef.current.focus();

        }, delay ? 650 : 0); // Timeout so that there's time for key listener to record
    }
    selectDateText() {
        let cssSelector = `#${this.guid} input`;
        let input = document.querySelector(cssSelector);
        if (input)
            input.select();
    }
    onFocus() {
        this.setState({
            isFocused: true
        });
    }
    onBlur() {
        // setTimeout(() =>
        this.setState({
            isFocused: false
        });
        // }), 500); // Timeout so that there's time for key listener to record
    }
    render() {
        return (<div onBlur={this.onBlur} onFocus={this.onFocus} id={this.guid} >
            <KeyListenPure name={'dateInput'} {...(this.state.isFocused ? this.keyListenerDown : {})} />
            <Input
                value={this.state.value}
                disabled={this.props.disabled}
                className={classNames({ [styles.border]: this.props.border }, this.state.isError ? styles.error : null, styles.Pdp, styles[this.props.theme], this.props.className)}
                onChange={this.onChange}
                defaultValue={this.props.defaultValue}
                ref={this.datePickerRef}
            />
        </div>);
    }
}
export default PerformanceModal;