import { connect } from "react-redux";
import React from 'react';
import { Select, Spin } from 'antd';
import { LoadingOutlined } from "@ant-design/icons"
import { selectedCustomerUpdate } from '../redux/actions';
import Axios from 'axios';
import error from '../assets/error.png';
import 'antd/dist/antd.css';
import styles from './accountSelector.module.css';
const classNames = require('classnames');
const mapStateToProps = state => {
    return {
        theme: state.appState.theme,
        locale: state.appState.locale,
        selectedCompany: state.masterState.cinfo.selectedCompany,
        apiHost: state.appState.apiHost,
        token: state.appState.userState.jwt
    };
}
class AccountSelector extends React.Component {
    constructor(props) {
        super(props);
        let st = AccountSelector.compStateFactory();
        if (this.props.defaultDisplayValue)
            st.displayValue = this.props.defaultDisplayValue;
        this.state = st;
        this.getData = this.getData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.selectChange = this.selectChange.bind(this);
        this.keyDown = this.keyDown.bind(this);
        this.state = { isOpen: false };
    }
    static compStateFactory(_list = undefined, _df = false, _fe = false, _ferr = '', _dv = '') {
        return {
            list: _list,
            dataFetching: _df,
            fetchError: _fe,
            fetchErrorMsg: _ferr,
            displayValue: _dv
        };
    }
    checkCompany() {
        if (!this.props.selectedCompany) {
            this.setState(AccountSelector.compStateFactory(undefined, true, true, 'Select a company'));
            return true;
        }
        return false;
    }
    async getData(customerFilter) {
        if (this.checkCompany())
            return;
        let accounts = undefined;
        try {
            this.setState({ dataFetching: true });
            let resp = await Axios.get(`${this.props.apiHost}/collections/paymentMethods?cinfo=${this.props.selectedCompany.key}&filter=${customerFilter}`, {
                withCredentials: true,
                responseType: 'json',
                headers: {
                    Authorization: `Bearer ${this.props.token}`
                }
            });
            accounts = resp.data.responseData.accounts;
            accounts.push({
                ledgerAccountCode: 'CASH',
                ledgerAccountName: 'Cash',
                Company: this.props.selectedCompany
            });
        } catch (err) {
            this.setState(AccountSelector.compStateFactory(undefined, true, true, err.response.status + ': ' + err.response.statusText, 'Select a Payment Method'));
            return;
        }
        this.setState(AccountSelector.compStateFactory(accounts, false, false, '', "Select a Payment Method"));
    }
    getChildren() {
        const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
        if (this.state.list) {
            return this.state.list.map(account => (
                <Select.Option key={account.ledgerAccountCode} label={account.ledgerAccountName}>
                    {account.ledgerAccountName}
                </Select.Option>));
        } else if (this.state.dataFetching) {
            return <Select.Option value='loading' disabled={true}>
                <div className={classNames(styles.eamSpin, styles.ecsiFetching, styles[this.props.theme.className])}>
                    <Spin indicator={antIcon} />
                    <p>Fetching Payment Methods</p>
                </div>
            </Select.Option>
        } else if (this.state.fetchError) {
            return (
                <Select.Option disabled key='error'>
                    <div style={
                        {
                            height: 'auto',
                            display: 'block',
                            textAlign: 'center'
                        }
                    } className={classNames(styles.eamSpin, styles.ecsiFetching, styles[this.props.theme.className])}>
                        <img src={error} style={
                            {
                                display: 'block',
                                height: '100px',
                                width: '100px',
                                textAlign: 'center',
                                margin: 'auto'
                            }
                        } />
                        {this.state.fetchErrorMsg}
                    </div>
                </Select.Option>
            );
        }
        else
            return null;
    }

    DropDownOpen(isOpen) {
        this.setState({ isOpen });
    }
    keyDown(e) {
        if (e.key == 'Escape' || e.key == 'Tab')
            this.DropDownOpen(false);
        if (e.key == 'Enter') {
            this.DropDownOpen(!this.state.isOpen);
        }
    }
    selectChange(selected, option) {

        if (this.props.onchange) {
            this.props.onchange({
                label: option.props.label,
                key: selected
            });
        }
    }
    getdefault(sc) {
        if (sc)
            return sc;
        else
            return undefined;
    }
    handleChange(value) {
        this.setState(AccountSelector.compStateFactory(this.state.list, false, false, '', value));
    }
    render() {
        let size = this.props.size;
        if (!size)
            size = 'default'
        return (
            <div onBlur={() => this.DropDownOpen(false)}>
                <Select showSearch value={this.state.displayValue} size={size} showArrow={false} filterOption={false} optionLabelProp='label' onSearch={this.getData} onChange={this.handleChange} onSelect={this.selectChange} dropdownClassName={classNames(styles.eamSelectItem, styles[this.props.theme.className])} className={classNames(styles.eamSelect, styles[this.props.theme.className])}
                    onInputKeyDown={this.props.onKeyPress} open={this.state.isOpen} onKeyDown={this.keyDown} onFocus={() => this.DropDownOpen(true)} ref={this.props.innerRef}>
                    {
                        this.getChildren()
                    }
                </Select >
            </div>);
    }
}
const EAMAccountSelector = connect(mapStateToProps)(AccountSelector);
export default EAMAccountSelector;