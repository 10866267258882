import { Redirect } from 'react-router-dom';
import React from 'react';
import 'antd/dist/antd.less';
import styles from './appHeader.module.css';
import whitelogo from '../assets/book-whitish.png';
import EAMIdentity from './EAMidentity.jsx';
import EAMCompanySelector from './EAMcompanySelector'
import { connect } from "react-redux";
import classNames from 'classnames';
import { Skeleton } from 'antd';
//import { EAMCompanySelector } from './components/companySelector';
const mapStateToProps = state => {
  return {
    appName: state.appState.appName,
    theme: state.appState.theme
  };
}
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.homeButtonClick = this.homeButtonClick.bind(this);
  }
  homeButtonClick() {
    document.location = '/';
  }
  renderNormalPage() {
    return (
      <div className={classNames(styles.navigationBar, styles[this.props.theme.className])}>
        <button className={styles.homeButton} onClick={this.homeButtonClick}>
          <img className={styles.appLogo} src={whitelogo} alt={''} />
          <em className={styles.appHeading}>{this.props.appName}</em>
        </button>
        {!this.props.authRequired && !this.props.skeleton ?
          <>
            <div className={styles.eamCompany}>
              <EAMCompanySelector location={this.props.location} />
            </div>
            <div className={styles.eamID}>
              <EAMIdentity />
            </div>
          </>
          :
          null}
      </div>);
  }
  render() {
      return this.renderNormalPage();
  }
}
const AppHeader = connect(mapStateToProps)(Header);
export default AppHeader;