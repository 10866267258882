import * as fileSave from 'file-saver'
import * as xls from 'xlsx';
import { format } from 'date-fns'
import { Modal } from 'antd'
import * as Icons from '@ant-design/icons';
export const parseCharset = str => {
    if (!str) {
        return 'utf-8';
    }
    let charsetregExp = new RegExp('(?<=charset=)[^;]+(?=;?)', 'mi');
    let match = str.match(charsetregExp);
    if (match)
        return match[0];
    else
        return 'utf-8';
}
export const parseContentType = str => {
    if (!str) {
        return 'application/json';
    }
    let regExp = new RegExp('[^;]+(?=;?)', 'mi');
    let match = str.match(regExp);
    if (match)
        return match[0];
    else
        return 'application/json';
};
export const isInViewport = elem => {
    if (!elem)
        return false;
    var bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};
export const getCookie = cname => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};
export const padInt = (
    /**
     * @param {number} number
     * @param {number} length
     * @param {number} precision
     * @returns {string}
     **/
    (number, length, precision) => {
        var str = '' + number.toFixed(precision);
        while (str.length < length) {
            str = '0' + str;
        }
        return str;
    });
export const getLang = () => {
    if (navigator.languages != undefined)
        return navigator.languages[0];
    else
        return navigator.language;
}
export const imageEncode = (arrayBuffer, mimeType) => {
    let u8 = new Uint8Array(arrayBuffer);
    let b64encoded = btoa([].reduce.call(u8, function (p, c) { return p + String.fromCharCode(c) }, ''));
    return "data:" + mimeType + ";base64," + b64encoded;
};
export const formatAsCurrency = (text, locale) => {
    if (text === undefined || text === '' || text === null)
        text = 0;
    if (typeof (text) === 'string') {
        text = parseFloat(text);
    }
    return text.toLocaleString(locale, { style: 'currency', currencyDisplay: 'symbol', currency: 'INR' });
};
export const uuidv4 = function () {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}
/**
 * 
 * @param {HTMLElement} element 
 * @param {string} className
 * @returns {ChildNode}
 */
export const GetFirstChildByClassName = ((element, className) => {
    let childElement = undefined
    for (var i = 0; i < element.childNodes.length; i++) {
        if (element.childNodes[i].className.includes(className)) {
            childElement = element.childNodes[i];
            break;
        }
    }
    return childElement;
});
/**
 * 
 * @param {string} className
 * @returns {number}
 */
export const SetMaxHeightToWindowBottom = (function (className) {
    const headerStyle = require('../components/appHeader.module.css');
    const titleStyle = require('../components/titleBar.module.css');
    let totheight = document.getElementsByTagName('body')[0].clientHeight;
    let headerHeight = document.getElementsByClassName(headerStyle.navigationBar)[0].clientHeight;
    let paymentsDisplayTitle = document.getElementsByClassName(titleStyle.title);
    let paymentsDisplayTitleHeight = paymentsDisplayTitle.length > 0 ? paymentsDisplayTitle[0].clientHeight : 0;
    let element = document.getElementsByClassName(className);
    if (element.length > 0) {
        let style = window.getComputedStyle(element[0]);
        let wrapperHeight = (totheight - headerHeight - paymentsDisplayTitleHeight - parseInt(style.marginTop.replace('px', '')));
        element[0].style.height = wrapperHeight + 'px';
        return wrapperHeight;
    }
    return 10;
});

/**
 * 
 * @param {string} str 
 * @returns {Object}
 */
export const QueryParser = str => {
    let obj = {};
    str = str.replace('?', '');
    for (let args of str.split('&')) {
        let keyValue = args.split('=');
        let value = keyValue.length == 1 ? true : keyValue[1];
        Object.defineProperty(obj, keyValue[0], {
            value: value,
            enumerable: true
        });
    }
    return obj;
}

/**
 * @param {string} str
 */
export const toTitleCase = str => str && str.toLowerCase().split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ');

export const multiSortMax10 = (
    /**
     * @param {Object} obj1
     * @param {Object} obj2
     * @param {Array.<{name:string,order:'ascend'|'descend'}>} fields
     * @returns {number}
     */
    (obj1, obj2, fields) => {
        const getValue = (obj, field) => {
            if (field.value instanceof Function)
                return field.value(obj);
            else if (typeof field.value === 'string')
                return obj[field.value];
            else
                return obj[field.name];

        };
        if (getValue(obj1, fields[0]) > getValue(obj2, fields[0])) return (fields[0].order === 'ascend' ? 1 : -1);
        else if (getValue(obj1, fields[0]) < getValue(obj2, fields[0])) return (fields[0].order === 'ascend' ? -1 : 1);
        else if (fields.length >= 2 && getValue(obj1, fields[1]) > getValue(obj2, fields[1])) return (fields[1].order === 'ascend' ? 1 : -1);
        else if (fields.length >= 2 && getValue(obj1, fields[1]) < getValue(obj2, fields[1])) return (fields[1].order === 'ascend' ? -1 : 1);
        else if (fields.length >= 3 && getValue(obj1, fields[2]) > getValue(obj2, fields[2])) return (fields[2].order === 'ascend' ? 1 : -1);
        else if (fields.length >= 3 && getValue(obj1, fields[2]) < getValue(obj2, fields[2])) return (fields[2].order === 'ascend' ? -1 : 1);
        else if (fields.length >= 4 && getValue(obj1, fields[3]) > getValue(obj2, fields[3])) return (fields[3].order === 'ascend' ? 1 : -1);
        else if (fields.length >= 4 && getValue(obj1, fields[3]) < getValue(obj2, fields[3])) return (fields[3].order === 'ascend' ? -1 : 1);
        else if (fields.length >= 5 && getValue(obj1, fields[4]) > getValue(obj2, fields[4])) return (fields[4].order === 'ascend' ? 1 : -1);
        else if (fields.length >= 5 && getValue(obj1, fields[4]) < getValue(obj2, fields[4])) return (fields[4].order === 'ascend' ? -1 : 1);
        else if (fields.length >= 6 && getValue(obj1, fields[5]) > getValue(obj2, fields[5])) return (fields[5].order === 'ascend' ? 1 : -1);
        else if (fields.length >= 6 && getValue(obj1, fields[5]) < getValue(obj2, fields[5])) return (fields[5].order === 'ascend' ? -1 : 1);
        else if (fields.length >= 7 && getValue(obj1, fields[6]) > getValue(obj2, fields[6])) return (fields[6].order === 'ascend' ? 1 : -1);
        else if (fields.length >= 7 && getValue(obj1, fields[6]) < getValue(obj2, fields[6])) return (fields[6].order === 'ascend' ? -1 : 1);
        else if (fields.length >= 8 && getValue(obj1, fields[7]) > getValue(obj2, fields[7])) return (fields[7].order === 'ascend' ? 1 : -1);
        else if (fields.length >= 8 && getValue(obj1, fields[7]) < getValue(obj2, fields[7])) return (fields[7].order === 'ascend' ? -1 : 1);
        else if (fields.length >= 9 && getValue(obj1, fields[8]) > getValue(obj2, fields[8])) return (fields[8].order === 'ascend' ? 1 : -1);
        else if (fields.length >= 9 && getValue(obj1, fields[8]) < getValue(obj2, fields[8])) return (fields[8].order === 'ascend' ? -1 : 1);
        else if (fields.length >= 10 && getValue(obj1, fields[9]) > getValue(obj2, fields[9])) return (fields[9].order === 'ascend' ? 1 : -1);
        else if (fields.length >= 10 && getValue(obj1, fields[9]) < getValue(obj2, fields[9])) return (fields[9].order === 'ascend' ? -1 : 1);
        else return 0;
    });

/**
 * @param {Array.<Object>} data
 * @param {string} filename
 * @param {string} cname
 * @param {string} dateIso
 * @param {title} title
 * @param {Array.<string>} [header]
 * @returns {void}
 */
export const exportToCSV = (data, fileName, cname, dateIso, title, header) => {
    let reportHeader = [
        [
            cname
        ],
        [
            title
        ],
        [
            'As on: ' + format(new Date(dateIso), "dd-MM-yyyy")
        ],
        [],
        [],
        header
    ];
    let fcount = 1;
    if (data.length > 0)
        fcount = Object.keys(data[0]).length;
    const merges = [{
        s: {
            r: 0,
            c: 0
        },
        e: {
            r: 0,
            c: fcount - 1
        }
    },
    {
        s: {
            r: 1,
            c: 0
        },
        e: {
            r: 1,
            c: fcount - 1
        }
    },
    {
        s: {
            r: 2,
            c: 0
        },
        e: {
            r: 2,
            c: fcount - 1
        }
    }
    ];
    console.log('fcouint:' +
        fcount);
    let ws = xls.utils.aoa_to_sheet(reportHeader);
    ws["!merges"] = merges;
    ws = xls.utils.sheet_add_json(ws, data, {
        origin: -1,
        skipHeader: header ? true : false
    });
    const wb = xls.utils.book_new();
    xls.utils.book_append_sheet(wb, ws, "data");
    xls.writeFile(wb, fileName, {
        bookType: "xlsx",
    });

};

export const onPaymentDelete = (paymentDisplay, payments, tableRef) => {
    tableRef.ignoreKey = true;
    const state = {
        modal: null
    }
    const { confirm, warning } = Modal;
    const icon = payments.length > 1 ? <Icons.CloseCircleFilled style={{
        color: "#ff4d4f"
    }} /> : <Icons.ExclamationCircleOutlined />;
    const onOkay = (async () => {
        state.modal?.destroy();
        for (let payment of payments) {
            let loadingRef = tableRef.getCellRef(payment.paymentNo, "loading")?.colInnerRef?.loadingRef;
            loadingRef?.current?.manualStateSet(true);
            try {
                await (this || paymentDisplay)?.deletePayment({ paymentNo: payment.paymentNo });
            }
            catch (err) {
                console.log(err);
            }
            loadingRef?.current?.manualStateSet(false);
            tableRef.ignoreKey = false;
        }
    }).bind(this, paymentDisplay);
    state.modal = confirm({
        icon,
        autoFocusButton: 'cancel',
        centered: true,
        content: payments?.length > 1 ? `Are you Sure want to Delete ${payments?.length} payments` : 'Are you Sure want to Delete',
        okButtonProps: {
            danger: payments.length > 1
        },
        okText: 'Delete',
        onOk: onOkay,
        onCancel: () => { if (!state.deleting) tableRef.ignoreKey = false }
    });
}

export const wait = async (ms) => {
    return new Promise(res => setTimeout(res, ms));
}

/**
 * Returns the given date or today with hours set to 0:0:0:0
 * @param {Date} [date]
 * @returns {Date}
 */
export const getDayStart = (date) => {
    if (!date)
        date = new Date();
    date.setHours(0,0,0,0);
    return date;
}

/**
 * Returns the given date or today with hours set to 23:59:59:999
 * @param {Date} [date]
 * @returns {Date}
 */
 export const getDayEnd = (date) => {
    if (!date)
        date = new Date();
    date.setHours(23,59,59,999);
    return date;
}
