import { connect } from "react-redux";
import React from 'react';
import { Select, Spin, Divider, Button, notification } from 'antd';
import { cinfoUpdate, selectedCompanyUpdate } from '../redux/actions';
import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons"
import Axios, { } from 'axios';
import error from '../assets/error.png';
import { getLang } from '../types/helper';
import 'antd/dist/antd.css';
import styles from './EAMcompanySelector.module.css';
const classNames = require('classnames');
const mapStateToProps = state => {
    return {
        theme: state.appState.theme,
        company: state.masterState.cinfo,
        locale: state.appState.locale,
        compState: state.masterState.cinfo.companySelectorState,
        appAuthRequired: state.appState.serverState.authRequired,
        apiHost: state.appState.apiHost,
        token: state.appState.userState.jwt
    };
}
const mapDispatchToProps = ((dispatch) => {
    return {
        updateCompanyData: (cinfo) => dispatch(cinfoUpdate(cinfo)),
        updateSelectedCompany: (payload) => dispatch(selectedCompanyUpdate(payload))
    };
});
class CompanySelector extends React.Component {
    constructor(props) {
        super(props);
        this.getcompanyData = this.getcompanyData.bind(this);
        this.selectChange = this.selectChange.bind(this);
    }
    static compStateFactory(_df = false, _fe = false, _ferr = '') {
        return {
            dataFetched: _df,
            fetchError: _fe,
            fetchErrorMsg: _ferr
        };
    }
    async componentDidMount() {
        await this.getcompanyData();
    }
    async componentDidUpdate(prevProps) {
        if ((prevProps.appAuthRequired > this.props.appAuthRequired) || this.props.compState.fetchError) {
            await this.getcompanyData();
        }
    }
    async getcompanyData() {
        let cinfo = [1, 2];
        try {
            let lang = getLang();
            let resp = await Axios.get(`${this.props.apiHost}/collections/cinfo?locale=${this.props.locale}`, {
                withCredentials: true,
                responseType: 'json',
                headers: {
                    Authorization: `Bearer ${this.props.token}`
                }
            });
            if (resp.status === 200)
                cinfo = resp.data.responseData.cinfo;
        } catch (err) {
            let errstr = '';
            if (err.response) errstr = err.response.status + ': ' + err.response.statusText;
            else errstr = err.toString();
            this.props.updateCompanyData({ cinfo: [], companySelectorState: CompanySelector.compStateFactory(true, true, errstr) });
            return;
        }
        let sc = this.tryFindSelectedCompany(cinfo) || cinfo.find(() => true);
        localStorage.setItem('cinfoSelected', sc.key);
        this.props.updateCompanyData({ cinfo, companySelectorState: CompanySelector.compStateFactory(true, false, ''), selectedCompany: sc });
    }
    getChildren() {
        const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
        if (this.props.company.list.length > 0 || this.props.compState.dataFetched) {
            if (!this.props.compState.fetchError) {
                return this.props.company.list.map(cinfo => (
                    <Select.Option key={cinfo.key} label={cinfo.companyName + '       ' + cinfo.companyDataYear + ' - ' + (parseInt(cinfo.companyDataYear) + 1)}>
                        <div>
                            <span style={{
                                display: 'inline-flex', margin: 0
                            }}>{cinfo.companyName}</span>
                            <span style={{
                                float: 'right',
                                margin: 0
                            }}>{cinfo.companyDataYear + ' - ' + (parseInt(cinfo.companyDataYear) + 1)}</span>
                        </div>
                    </Select.Option>));
            } else {
                return (
                    <Select.Option disabled key='error'>
                        <div style={
                            {
                                height: 'auto',
                                display: 'block',
                                textAlign: 'center'
                            }
                        } className={classNames(styles.eamCompanySpin, styles.ecsiFetching, styles[this.props.theme.className])}>
                            <img src={error} style={
                                {
                                    display: 'block',
                                    height: '100px',
                                    width: '100px',
                                    textAlign: 'center',
                                    margin: 'auto'
                                }
                            } />
                            {this.props.compState.fetchErrorMsg}
                        </div>
                    </Select.Option>
                );
            }
        } else {
            return <Select.Option value='loading' disabled={true}>
                <div className={classNames(styles.eamCompanySpin, styles.ecsiFetching, styles[this.props.theme.className])}>
                    <Spin indicator={antIcon} />
                    <p>Fetching company list</p>
                </div>
            </Select.Option>
        }
    }
    getDropDown() {
        if (this.props.company.list.length > 0 || this.props.compState.dataFetched) {
            return (menu => (
                <div className={classNames(styles.eamCompanySelectItem, styles[this.props.theme.className])}>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div
                        style={{ padding: '4px 8px', cursor: 'pointer' }}
                        onMouseDown={e => e.preventDefault()}
                        onClick={this.addItem}
                    >
                        <Button onClick={this.getcompanyData} >
                            <ReloadOutlined />
                            Refresh Company List
                        </Button>
                    </div>
                </div>
            ));
        } else
            return undefined;
    }
    tryFindSelectedCompany(array) {
        let scKey = localStorage.getItem('cinfoSelected');
        return array.find(x => x.key === scKey);
    }
    selectChange(selected) {
        notification.warn({ message: 'Page will reload now' });
        localStorage.setItem('cinfoSelected', selected);
        this.props.updateSelectedCompany(selected);
        window.setTimeout(() => window.location.reload(), 1000);
    }
    render() {
        let sc = undefined;
        if (this.props.company.selectedCompany)
            sc = this.props.company.selectedCompany.key;
        let disabled = (window.location.pathname !== '/' && sc);

        return (<Select mode disabled={disabled} optionLabelProp='label' onSelect={this.selectChange} dropdownClassName={classNames(styles.eamCompanySelectItem, styles[this.props.theme.className])} className={classNames(styles.eamCompanySelect, styles[this.props.theme.className])} placeholder='Select a Company' value={sc}
            dropdownRender={
                this.getDropDown()
            }>
            {
                this.getChildren()
            }
        </Select >);
    }
}
const EAMCompanySelector = connect(mapStateToProps, mapDispatchToProps)(CompanySelector);
export default EAMCompanySelector;